<template>
    <div>
        <!-- 轮播图 -->
        <div class="tour-slide">
            <van-swipe :autoplay="3000" :indicator-color="config.main_color" class="swipe" :loop="false">
                <van-swipe-item v-for="(item,index) in slide" :key="index">
                    <img :src="item" style="width:100%;height:250px;">
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 景区信息 -->
        <div class="top">
            <div class="tour-name">
                {{data.tour_name}}
            </div>

            <div class="tour-time">
                开园时间：{{data.tour_time}}
            </div>

            <div class="tour-time">
                客服电话：<a :href="'tel://'+data.tour_tel">{{data.tour_tel}}</a>
            </div>

            <div class="tour-address">
                景区地址：{{data.tour_address}}
            </div>
        </div>

        <van-notice-bar left-icon="volume-o" :text="data.tour_tip" @click="show_tip" v-if="data.tour_tip" />

        <!-- 票型信息 -->
        <div class="tour-content">
            <div class="title">
                <div class="title-text">套票列表</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="tour-package">
                <div class="tour-package-list" v-for="(item,index) in package" :key="index">
                    <div class="package-name">{{item.package_name}}</div>
                    <div class="package-price">￥{{item.package_price}}</div>
                    <div class="package-button" @click="show_detail(index)">预订须知 ></div>
                    <div class="package-add">
                        <van-stepper v-model="item.number" theme="round" button-size="22" :min="0" :max="item.package_max" 
                            @plus="action = 'plus'"
                            @minus="action = 'minus'"
                            @change="change($event,index)" color="#000" disable-input :show-minus="true"
                            :show-input="true" />
                    </div>
                </div>
            </div>
        </div>

        <!-- 实名信息 -->
        <div class="tour-content">
            <div class="title">
                <div class="title-text">实名信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="tour-form">
                <van-form @submit="submit">
                    <van-cell-group>
                        <van-field label-width="50" v-model="user.name" name="姓名" label="姓名" placeholder="请输入姓名"
                            :rules="[{ required: true}]"></van-field>

                        <van-field label-width="50" @touchstart.stop="show_sfz = true;show_tel = false;" readonly
                            clickable v-model="user.sfz" name="身份证" label="身份证" placeholder="请输入身份证"
                            :rules="[{ required: true}]"></van-field>
                        <van-number-keyboard v-model="user.sfz" :show="show_sfz" extra-key="X" close-button-text="完成"
                            @blur="show_sfz = false"></van-number-keyboard>

                        <van-field label-width="50" @touchstart.stop="show_tel = true;show_sfz = false;" readonly
                            clickable v-model="user.tel" name="电话" label="电话" placeholder="请输入电话"
                            :rules="[{ required: true}]"></van-field>
                        <van-number-keyboard :show="show_tel" @blur="show_tel = false" v-model="user.tel">
                        </van-number-keyboard>
                    </van-cell-group>
                </van-form>
            </div>
        </div>

        <!-- 景区介绍 -->
        <div class="tour-content" style="margin-bottom: 70px;">
            <div class="title">
                <div class="title-text">景区介绍</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="tour-content2" v-html="data.tour_content"></div>
        </div>

        <!-- 底部按钮 -->
        <div class="tour-footer">
            <div class="tour-footer-left">
                <div class="tour-footer-number">已选：<span>{{number}}张</span></div>
                <div class="tour-footer-price">总额：<span>￥{{price}}</span></div>
            </div>
            <div class="tour-footer-button">
                <van-button type="button" @click="submit" native-type="submit">立即预订</van-button>
            </div>
        </div>

        <div class="back-order" @click="url('/order')">
            订单
        </div>

        <!-- 预订须知 -->
        <van-popup v-model:show="show_content" position="bottom" :style="{ height: '70%' }" closeable round>
            <div class="show-name">
                <div>{{item_package.package_name}}</div>
            </div>
            <div class="show-detail">
                1、该套票包含 <span v-for="(item,index) in item_package.scenic" :key="index">{{item.scenic_name}}、</span>
                <br>
                2、使用时间：下单后{{data.tour_time_use}}天之内。 <br>
                3、退款时间：下单后{{data.tour_time_refund}}天之内，在此时间内如未发生任何消费行为可以申请退款。 <br>
                4、使用后不予许退款，开始使用后必须在当天晚上24点前完成其他景点的消费，超时系统会自动确认核销。<br>
                5、{{item_package.package_content}}
            </div>
        </van-popup>

    </div>
</template>

<script>
    import {
        package_data,
        package_submit,
        package_pay
    } from '@/api/package.js';
    import {
        Toast,
        Notify,
        Dialog
    } from 'vant';
    import { wxPay } from '@/static/js/pay.js';
    export default {
        name: 'package',
        data() {
            return {
                loading: false,
                slide: [],
                data: {},
                package: [],
                item_package: {}, //选择操作的套票
                show_tel: false,
                show_sfz: false,
                show_content: false,
                sale_id: '',
                user: {
                    name: '',
                    sfz: '',
                    tel: '',
                },
                action:'plus',//增加还是减
            }
        },
        computed: {
            number() {
                let data = this.package;
                let number = 0;
                for (let item of data) {
                    number = number + item.number;
                }
                return number;
            },
            price() {
                let data = this.package;
                let price = 0;
                for (let item of data) {
                    price = price + item.number * item.package_price;
                }
                return price.toFixed(2);
            },
        },
        mounted() {
            this.save_sale();
            this.get_data();
        },
        methods: {
            save_sale() {
                const sale_id = this.$store.getters.sale_id;
                if (this.$route.params.sale_id) {
                    this.sale_id = this.$route.params.sale_id;
                } else {
                    this.sale_id = this.$route.query.sale_id;
                }
                if (this.sale_id) {
                    this.$store.commit('setSaleId', this.sale_id);
                }

                if (!this.sale_id && sale_id) {
                    this.sale_id = sale_id;
                }
            },

            show_tip() {
                Dialog.alert({
                    title: '温馨提示',
                    message: this.data.tour_tip,
                }).then();
            },

            //预订须知
            show_detail(index) {
                this.item_package = this.package[index];
                this.show_content = true;
            },

            //监听变化
            change(e, index) {
                var item = this.package[index];
                if (e < item.package_min) {
                    if(this.action == 'plus'){
                        item.number = item.package_min;
                        this.$set(this.package,index,item);
                    }else{
                        item.number = 0;
                        this.$set(this.package,index,item);
                    }
                }
                if (e > item.package_max) {
                    item.number = item.package_max;
                    this.$set(this.package,index,item);
                }
            },


            //提交
            submit() {
                if (!this.number) {
                    Toast('所选套票数量不得为0！');
                    return false;
                }

                if (!this.user.name) {
                    Toast('姓名不得为空！');
                    return false;
                }

                if (!this.user.sfz) {
                    Toast('身份证不得为空！');
                    return false;
                }

                if (this.user.sfz.length !== 18) {
                    Toast('身份证长度不合法！');
                    return false;
                }

                if (!this.user.tel) {
                    Toast('电话不得为空！');
                    return false;
                }


                if (this.user.tel.length !== 11) {
                    Toast('电话长度不合法！');
                    return false;
                }


                const data = {
                    user: this.user,
                    package: this.package,
                    sale_id: this.sale_id, //当前的代理id
                    tour_id: this.$route.query.tour_id ? this.$route.query.tour_id : 1,
                    loading: true,
                    loading_text: '购买中……'
                };
                package_submit(data).then(res => {
                    if (res.code == 1) {
                        this.pay(res.data);
                    }
                });
            },

            //提交支付
            pay(result) {
                package_pay({
                    id: result.order_id
                }).then(res => {
                    if (res.code == 1) {
                        //无需付款
                        if (res.data.info == 1) {
                            this.url('/package_success?id=' + id);
                            return true;
                        }

                        wxPay(res.data.info).then(res2 => {
                            that.$router.replace('/order_detail?id=' + res.data.id);
                        }).catch(err => {
                            that.$router.replace('/order_detail?id=' + res.data.id);
                        });
                    }
                });
            },


            get_data() {
                package_data({
                    tour_id: this.$route.query.tour_id,
                }).then(res => {
                    if (res.code == 1) {
                        this.data = res.data;
                        this.slide = res.data.tour_slide;
                        this.package = res.data.package;
                        this.user = res.data.user;
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        padding: 20px;
        background: #fff;
    }

    .tour-content {
        width: calc(100% - 40px);
        margin: 20px;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .tour-slide img {
        width: 100%;
    }


    .tour-name {
        font-size: 18px;
        font-weight: 700;
        color: #333;
        line-height: 30px;
    }

    .tour-time {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 30px;
    }

    .tour-address {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 30px;
    }

    .tour-tip {
        font-size: 14px;
        font-weight: 400;
        color: var(--main-color);
        line-height: 30px;
    }

    .tour-time span {
        margin-left: 30px;
    }

    .tour-time span a {
        color: var(--main-color);
    }

    .tour-package {
        margin: 15px;
    }

    .tour-package-list {
        background-color: #f1f1f1;
        border-radius: 10px;
        position: relative;
        height: auto;
        margin-bottom: 15px;
        padding-bottom: 5px;
    }

    .package-name {
        width: calc(100% - 100px);
        font-size: 14px;
        color: #555;
        font-weight: 600;
        margin: 15px 0 0 10px;
        padding-top: 10px;
    }

    .package-price {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 80px;
        font-size: 18px;
        color: var(--main-color);
        font-weight: 700;
        text-align: right;
        float: right;
        vertical-align: top;
    }

    .package-button {
        font-size: 12px;
        color: var(--main-color);
        font-weight: 400;
        margin: 15px 0 10px 15px;
    }

    .package-add {
        position: absolute;
        right: 10px;
        bottom: 15px;
        font-size: 14px;
        color: var(--main-color);
        font-weight: 400;
    }

    .list-name {
        font-size: 16px;
        line-height: 40px;
        font-weight: 700;
        color: #333;
    }

    .tour-footer {
        height: 50px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        line-height: 50px;
    }

    .tour-footer-button button {
        height: 30px;
        line-height: 30px;
        position: absolute;
        right: 15px;
        top: 10px;
        background-color: var(--main-color);
        text-align: center;
        padding: 0 20px;
        font-size: 14px;
        color: #fff;
        border-radius: 5px;
        border: none;
    }

    .tour-footer-left {
        font-size: 14px;
        color: #444;
        padding-top: 9px;
    }

    .tour-footer-left div {
        line-height: 16px;
        height: 16px;
        font-size: 12px;
        margin-left: 15px;
    }

    .tour-footer-price span {
        color: var(--main-color);
    }

    /deep/ .tour-content2 img {
        max-width: 100% !important;
        background: #fff;
    }

    .tour-content2 img {
        max-width: 100% !important;
        background: #fff;
    }

    .tour-content2 {
        padding: 15px;
    }

    .show-detail {
        padding: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #555;
        line-height: 2.5;
    }

    .show-name {
        height: 50px;
        width: 100%;
    }

    .show-name div {
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
        font-size: 16px;
        font-weight: 700;
        width: calc(100% - 50px);
        color: #333;
        margin-left: 15px;
    }

    .back-order {
        position: fixed;
        top: 30px;
        left: 20px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        background-color: var(--main-color);
        font-size: 12px;
        color: #fff;
    }

    /deep/ .van-stepper--round .van-stepper__plus {
        background-color: var(--main-color);
    }

    /deep/ .van-stepper--round .van-stepper__minus {
        color: var(--main-color);
        border-color: var(--main-color);
    }
</style>